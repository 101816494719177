@font-face {
	font-family: 'fontello';
	src: url('../fonts/fontello.eot?87440325');
	src: url('../fonts/fontello.eot?87440325#iefix') format('embedded-opentype'),
	url('../fonts/fontello.woff2?87440325') format('woff2'),
	url('../fonts/fontello.woff?87440325') format('woff'),
	url('../fonts/fontello.ttf?87440325') format('truetype'),
	url('../fonts/fontello.svg?87440325#fontello') format('svg');
	font-weight: normal;
	font-style: normal;
}