$font1: 'Rubik', sans-serif;
$font2: 'Roboto', sans-serif;

$color-white: #fff;
$color-black: #111;
$color-main: #b7000e;
$color-main-hover: #e00011;
$color-main-active: #89000b;
$color-grey: #7e7d81;
$color-grey2: #ccc;

$shadow: 0 15px 20px rgba(0,0,0,0.05);

// Sass Breakpoint Mixin
@mixin breakpoint($point) {
	@if $point == p_sm {
		@media (min-width: 480px) { @content; }
	}
	@else if $point == p_md {
		@media (min-width: 768px) { @content; }
	}
	@else if $point == p_lg {
		@media (min-width: 1000px) { @content; }
	}
	@else if $point == p_xl {
		@media (min-width: 1256px) { @content; }
	}
	@else if $point { // allows custom breakpoints
		@media (min-width: $point) { @content; }
	}
}
// Customization Bootstrap vars from "app/libs/bootstrap/scss/_variables.scss, only before @import bootstrap variables!"
$grid-gutter-width: 20px;
$grid-columns: 12;
$grid-breakpoints: (xs: 0, sm: 480px, md: 768px, lg: 1000px, xl: 1256px);
$container-max-widths: (sm: 480px, md: 768px, lg: 1000px, xl: 1256px);

$gutter: $grid-gutter-width;
@import 'fonts';
@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import 'reset';

//@import '../../node_modules/bootstrap/scss/bootstrap-grid.scss';
//@import '../../node_modules/bootstrap/scss/variables';

html {
	font-size: 9px;
	@include breakpoint(p_md) {
		font-size: 10.5px;
	}
	@include breakpoint(p_lg) {
		font-size: 12.5px;
	}
	@include breakpoint(p_xl) {
		font-size: 15px;
	}
}
body {
	min-width: 320px;
	color: $color-black;
	font-family: $font1;
	line-height: 1.2;
	background-color: $color-white;
}

.container {
	height: 100%;
	padding-right: 20px;
	padding-left: 20px;
}

.bg-layout-cover {
	background: center/cover no-repeat;
}
.bg-layout-contain {
	background: center/contain no-repeat;
}

h1,
h2 {
	font-family: $font2;
	font-size: 28px;
	font-weight: 700;
	text-align: center;
	margin-bottom: 20px;
	@include breakpoint(p_sm) {
		font-size: 32px;
	}
	@include breakpoint(p_md) {
		font-size: 36px;
		margin-bottom: 25px;
	}
	@include breakpoint(p_lg) {
		font-size: 42px;
		margin-bottom: 30px;
	}
}
h3 {
	font-size: 20px;
	font-weight: 500;
	margin-bottom: 20px;
	@include breakpoint(p_sm) {
		font-size: 24px;
	}
	@include breakpoint(p_md) {
		font-size: 28px;
	}
	@include breakpoint(p_lg) {
		font-size: 32px;
	}
}
h4 {
	font-size: 24px;
	font-weight: 500;
	margin-bottom: 10px;
}

.icon-sprite {
	display: block;
	transition: 0.3s ease;
}

.modal-body.eula {
	color: $color-white;
}

strong,
b {
	font-weight: bold !important;
}

.main-color-text {
	color: $color-main;
}
.grey-text {
	color: $color-grey;
}

.inline-link {
	&:hover {
		color: $color-main;
	}
}

.typical-text {
	font-size: 15px;
	line-height: 1.4;
	p,	ul, ol,
	.img-group,
	.article-tab-group {
		margin-bottom: 15px;
		@include breakpoint(p_sm) {
			margin-bottom: 25px;
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	ul, ol {
		li + li {
			margin-top: 15px;
			@include breakpoint(p_sm) {
				margin-top: 25px;
			}
		}
	}
	ul {
		li {
			padding-left: 26px;
			&::before {
				content: '';
				display: inline-block;
				width: 13px;
				height: 2px;
				background-color: $color-main;
				margin: 0 13px 0 -26px;
				vertical-align: middle;
			}
		}
	}
	ol {
		counter-reset: num;
		li {
			padding-left: 32px;
			&::before {
				content: counter(num);
				counter-increment: num;
				display: inline-block;
				min-width: 10px;
				color: $color-main;
				font-weight: 500;
				margin: 0 22px 0 -32px;
			}
		}
	}
}

.img-wrap {
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
}

.main-color-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	color: $color-white !important;
	background-color: $color-main;
	font-weight: 500;
	border-radius: 4px;
	&:hover {
		background-color: $color-main-hover;
	}
	&:active {
		background-color: $color-main-active;
	}
	span {
		vertical-align: middle;
	}
	.icon-smartphone {
		width: 16px;
		height: 22px;
		stroke: $color-white;
		fill: none;
	}
	.icon-arr_right_circle {
		width: 22px;
		height: 22px;
		fill: none;
		stroke: $color-white;
	}
	.icon-envelop {
		width: 22px;
		height: 18px;
		stroke: $color-white;
		fill: none;
	}
}
.main-color-contour-btn {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 60px;
	color: $color-black !important;
	font-size: 20px;
	font-weight: 500;
	background-color: $color-white;
	padding: 0 25px;
	border: 2px solid $color-main;
	border-radius: 10px;
	.icon-arr_right_circle {
		width: 22px;
		height: 22px;
		fill: none;
		stroke: $color-main;
	}
}
.a-link {
	display: flex;
	align-items: center;
	.icon-arr_right_circle {
		width: 22px;
		height: 22px;
		fill: none;
		stroke: $color-main;
	}
}

.socials-group {
	a {
		display: block;
		+ a {
			margin-left: 10px;
		}
	}
}

.bg-marble {
	background: url(../img/bg_marble.png) center;
}

.mb-40 {
	margin-bottom: 40px;
}


/* input-placeholder */
input::-webkit-input-placeholder {
	opacity: 1;
	transition: opacity 0.3s ease;
}
input::-moz-placeholder {
	opacity: 1;
	transition: opacity 0.3s ease;
}
input:-ms-input-placeholder {
	opacity: 1;
	transition: opacity 0.3s ease;
}
input:focus::-webkit-input-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
input:focus::-moz-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
input:focus:-ms-input-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
textarea::-webkit-input-placeholder {
	opacity: 1;
	transition: opacity 0.3s ease;
}
textarea::-moz-placeholder {
	opacity: 1;
	transition: opacity 0.3s ease;
}
textarea:-ms-input-placeholder {
	opacity: 1;
	transition: opacity 0.3s ease;
}
textarea:focus::-webkit-input-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
textarea:focus::-moz-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
textarea:focus:-ms-input-placeholder {
	opacity: 0;
	transition: opacity 0.3s ease;
}
input::-ms-clear {
	display: none;
}
input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
	display: none;
}


/* icons */
[class^="icon-"]::before, [class*=" icon-"]::before {
	font-family: 'fontello';
	font-style: normal;
	font-weight: normal;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	text-align: center;
	/* For safety - reset parent styles, that can break glyph codes*/
	font-variant: normal;
	text-transform: none;
	/* fix buttons height, for twitter bootstrap */
	line-height: 1em;
	/* Font smoothing. That was taken from TWBS */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-right-open::before { content: '\e800'; }
.icon-down-open::before { content: '\e801'; }
.icon-left-open::before { content: '\e802'; }
.icon-up-open::before { content: '\e803'; }
/* </icons */


/* inputs */
.field {
	display: block;
	width: 100%;
	height: 60px;
	font-size: 17px;
	background-color: $color-white;
	padding: 0 24px;
	border-radius: 4px;
	&.v2 {
		border: 1px solid $color-grey2;
	}
	&.v3 {
		height: 50px;
		border: 1px solid $color-grey2;
	}
	&.v4 {
		margin-top: -11px;
		&:focus {
			border-color: #69a042;
		}
	}
}
textarea.field {
	height: 200px;
	padding-top: 20px;
	padding-bottom: 20px;
	resize: none;
	&.v3 {
		height: 150px;
	}
}

.inputs-group {
	margin-top: 20px;
	&:first-child {
		margin-top: 0;
	}
	.inp-group {
		width: 100%;
		margin-top: 20px;
		@include breakpoint(p_md) {
			width: calc(33.333% - 13px);
			margin-top: 0;
		}
		&:first-child {
			margin-top: 0;
		}
	}
}

label.v1 {
	color: $color-grey;
	font-size: 13px;
	background-color: $color-white;
	padding: 0 8px 3px 0;
}

.chk-group,
.radio-group {
	margin-top: 20px;
	.chkbx-bl {
		font-size: 0;
	}
	.chkbx {
		display: none;
		&:checked {
			+ .chk-emu {
				background: url(../img/chk.svg) center no-repeat,
				$color-main;
			}
		}
	}
	.chk-emu {
		width: 30px;
		height: 30px;
		background: $color-main;
		border-radius: 4px;
		cursor: pointer;
	}
	.txt-label {
		max-width: 620px;
		color: $color-white;
		font-size: 15px;
		padding-left: 12px;
		cursor: pointer;
		@include breakpoint(p_md) {
			font-size: 17px;
		}
		@include breakpoint(p_xl) {
			max-width: none;
		}
		&.v2 {
			color: $color-black;
		}
	}
}

.radio-group {
	.radio {
		display: none;
		&:checked {
			+ .radio-emu {
				border-color: $color-main;
			}
		}
	}
	.radio-emu {
		width: 30px;
		height: 30px;
		background: $color-white;
		border: 9px solid transparent;
		border-radius: 50%;
		cursor: pointer;
	}
}

.jq-selectbox {
	height: 50px;
	font-size: 17px;
	border: 1px solid $color-grey2;
	border-radius: 4px;
	cursor: pointer;
	.jq-selectbox__select {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
	}
	.jq-selectbox__select-text {
		padding-left: 15px;
	}
	.jq-selectbox__trigger {
		font-size: 20px;
		padding: 0 15px 0 10px;
		margin: 2px -3px 0 0;
		span {
			display: inline-block;
			color: $color-main;
			&.rotated {
				transform: rotateZ(180deg);
			}
		}
	}
	.jq-selectbox__dropdown {
		width: 100%;
		background-color: $color-white;
		margin-top: 2px;
		ul {
			padding: 5px 0;
			border: 1px solid $color-grey2;
			border-radius: 4px;
			li {
				white-space: normal;
				padding: 5px 15px;
				&:hover {
					color: $color-white;
					background-color: $color-main;
				}
			}
		}
	}
}
/* </inputs */

/* breadcrumb */
.breadcrumb {
	flex-wrap: nowrap;
	height: 60px;
	font-size: 15px;
	background-color: transparent;
	padding: 0;
	margin: 0;
	.breadcrumb-item {
		flex-shrink: 0;
		+ .breadcrumb-item {
			padding-left: 1rem;
			&::before {
				color: $color-grey2;
				padding-right: 1rem;
			}
		}
		&.active {
			flex-shrink: 1;
			overflow: hidden;
			color: $color-black;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		a {
			color: $color-main;
		}
	}
}
/* </breadcrumb */

/* pagination */
.pagination {
	margin-top: 20px;
	li {
		&.active {
			a {
				color: $color-white;
				background-color: $color-main;
			}
		}
	}
	.pg-link {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		font-size: 15px;
		background-color: $color-white;
		border-radius: 4px;
		box-shadow: $shadow;
		@include breakpoint(p_sm) {
			width: 60px;
			height: 60px;
			font-size: 20px;
		}
	}
}
/* </pagination */

/* cats-menu */
.cats-menu {
	top: 100%;
	left: 0;
	font-size: 15px;
	font-weight: 400;
	background-color: $color-grey2;
	@include breakpoint(p_md) {
		position: absolute;
		width: 480px;
	}
	@include breakpoint(p_lg) {
		position: static;
		width: auto;
		font-size: 17px;
		background-color: transparent;
	}
	&.modified {
		overflow-y: auto;
	}
	> li {
		position: relative;
		color: $color-black;
		margin-bottom: 1px;
		@include breakpoint(p_lg) {
			width: auto;
		}
		&:hover {
			color: $color-white;
			> a {
				background-color: $color-main;
			}
			> i {
				color: $color-white;
			}
		}
		> a {
			display: block;
			background-color: $color-white;
			padding: 15px 35px 15px 20px;
			@include breakpoint(p_lg) {
				border-radius: 4px;
				box-shadow: $shadow;
			}
		}
		> i {
			display: block;
			width: 26px;
			position: absolute;
			top: 12px;
			right: 10px;
			z-index: 10;
			font-size: 22px;
			color: $color-grey2;
			text-align: center;
			transform: rotateZ(90deg);
			@include breakpoint(p_lg) {
				transform: none;
			}
			&.rotated {
				transform: rotateZ(-90deg);
				@include breakpoint(p_lg) {
					transform: none;
				}
			}
		}
		> ul {
			display: none;
			left: 100%;
			top: -15px;
			z-index: 3;
			background-color: #eee;
			padding: 15px 20px;
			box-shadow: $shadow;
			@include breakpoint(p_lg) {
				position: absolute;
				width: 320px;
				background-color: $color-white;
				padding: 20px 25px;
				border: 2px solid $color-main;
				border-radius: 4px;
			}
			> li {
				position: relative;
				color: $color-black;
				padding: 10px 0;
				margin-right: -10px;
				&:hover {
					> a {
						color: $color-main;
					}
				}
				> a {
					display: block;
				}
				> i {
					display: block;
					width: 26px;
					position: absolute;
					top: 4px;
					right: 0;
					z-index: 10;
					font-size: 22px;
					color: $color-grey2;
					text-align: center;
					transform: rotateZ(90deg);
					@include breakpoint(p_lg) {
						transform: none;
					}
					&.rotated {
						transform: rotateZ(-90deg);
						@include breakpoint(p_lg) {
							transform: none;
						}
					}
				}
				> ul {
					display: none;
					left: 100%;
					top: -15px;
					z-index: 3;
					background-color: #eee;
					padding: 15px 20px;
					box-shadow: $shadow;
					@include breakpoint(p_lg) {
						position: absolute;
						width: 250px;
						background-color: $color-white;
						padding: 20px 25px;
						border: 2px solid $color-main;
						border-radius: 4px;
					}
					@include breakpoint(p_lg) {
						width: 320px;
					}
					> li {
						color: $color-black;
						padding: 5px 0;
						&:hover {
							> a {
								color: $color-main;
							}
						}
					}
				}
			}
		}
	}
}
/* </cats-menu */

/* msgs-links */
.links-group {
	font-size: 15px;
	@include breakpoint(p_sm) {
		font-size: 17px;
	}
	@include breakpoint(p_xl) {
		font-size: 20px;
	}
	.msgs {
		font-size: 0;
	}
}
/* </msgs-links */


/* main-color-border-links */
.main-color-border-links {
	a {
		height: 50px;
		font-size: 13px;
		margin-right: 10px;
		@include breakpoint(p_sm) {
			height: 60px;
			font-size: 20px;
		}
	}
}
/* </main-color-border-links */


/* stick */
.stick {
	position: absolute;
	bottom: 0;
	left: 0;
	z-index: 1;
	color: $color-white;
	font-size: 17px;
	font-weight: 500;
	background-color: $color-main;
	padding: 7px 45px 9px 27px;
	border-radius: 0 36px 0 0;
}
/* </stick */


/* header */
header,
footer {
	&.changed {
		position: fixed;
		right: 0;
		left: 0;
		z-index: 100;
	}
	.logo {
		width: 132px;
		@include breakpoint(p_sm) {
			width: 192px;
		}
		@include breakpoint(p_xl) {
			width: 226px;
		}
	}
	.subtext {
		font-size: 9px;
		@include breakpoint(p_sm) {
			font-size: 12.5px;
		}
		@include breakpoint(p_xl) {
			font-size: 15px;
		}
	}
	.item-subp {
		font-size: 13.5px;
		@include breakpoint(p_xl) {
			font-size: 17px;
		}
		&.v2 {
			font-size: 27px;
			@include breakpoint(p_xl) {
				font-size: 33px;
			}
		}
	}
	.main-color-btn {
		width: 160px;
		height: 40px;
		font-size: 13px;
		@include breakpoint(p_lg) {
			width: 200px;
			font-size: 15px;
		}
		&.v2 {
			width: 135px;
			@include breakpoint(p_lg) {
				width: 200px;
			}
		}
	}
	.top-header {
		background-color: $color-white;
		.top-header-inner {
			height: 75px;
			@include breakpoint(p_sm) {
				height: 96px;
			}
			@include breakpoint(p_xl) {
				height: 116px;
			}
			&.v2 {
				@include breakpoint(p_sm) {
					padding-top: 25px;
				}
			}
		}
		.item {
			@include breakpoint(p_xl) {
				min-width: 200px;
			}
		}
	}
	.btm-header {
		background-color: $color-black;
		.btm-header-inner {
			position: relative;
			min-height: 54px;
			@include breakpoint(p_lg) {
				min-height: 60px;
			}
		}
		.burger {
			color: $color-white;
			font-family: $font2;
			font-size: 15px;
			font-weight: 700;
			background-color: transparent;
			span {
				vertical-align: middle;
			}
			.icon-crossy {
				display: none;
				width: 24px;
				height: 16px;
				stroke: $color-white;
				fill: none;
				vertical-align: middle;
			}
			&.crossed {
				.icon-burger {
					display: none;
				}
				.icon-crossy {
					display: inline-block;
				}
			}
		}
		.icon-burger {
			display: inline-block;
			width: 24px;
			height: 16px;
			stroke: $color-white;
			fill: none;
			vertical-align: middle;
		}
		.m-link {
			color: $color-white;
			font-size: 15px;
			font-weight: 500;
			@include breakpoint(p_lg) {
				font-size: 17px;
			}
			@include breakpoint(p_xl) {
				font-size: 22px;
			}
			&:hover {
				color: $color-main;
				.icon-burger {
					stroke: $color-main;
				}
			}
		}
		.main-menu {
			overflow-y: auto;
			display: none;
			position: absolute;
			top: 100%;
			right: -20px;
			left: -20px;
			z-index: 102;
			max-height: 600px;
			color: $color-white;
			font-size: 20px;
			font-weight: 500;
			background-color: $color-black;
			@include breakpoint(p_md) {
				overflow-y: visible;
				position: static;
				font-size: 15px;
				max-height: none;
			}
			@include breakpoint(p_lg) {
				font-size: 17px;
			}
			@include breakpoint(p_xl) {
				font-size: 22px;
			}
			> li {
				position: relative;
				border-top: 1px solid rgba(204,204,204,0.25);
				@include breakpoint(p_md) {
					border: none;
				}
				+ li {
					@include breakpoint(p_md) {
						margin-left: 22px;
					}
					@include breakpoint(p_lg) {
						margin-left: 42px;
					}
					@include breakpoint(p_xl) {
						margin-left: 65px;
					}
				}
				> a,
				> button {
					display: inline-block;
					width: 100%;
					color: $color-white;
					background-color: transparent;
					padding: 18px 15px;
					vertical-align: middle;
					@include breakpoint(p_md) {
						padding: 18px 0;
					}
				}
				&:hover {
					> a,
					> button {
						color: $color-main;
					}
					.icon-burger {
						stroke: $color-main;
					}
				}
			}
			.cats-menu {
				@include breakpoint(p_lg) {
					display: none;
				}
			}
		}
	}
}
/* </header */


/* main */
.main-screen {
	position: relative;
	height: 380px;
	background-color: #2e303f;
	@include breakpoint(p_lg) {
		height: 496px;
	}
	@include breakpoint(p_xl) {
		height: 960px;
	}
	.pos-a {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}
	.bg-video-wrap {
		z-index: 1;
		> video {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
	.main-screen-content {
		z-index: 2;
		background-color: rgba(0,0,0,0.5);
	}
	.content-bl {
		max-width: 420px;
		@include breakpoint(p_lg) {
			max-width: 520px;
		}
		@include breakpoint(p_xl) {
			max-width: 600px;
		}
	}
	h1 {
		color: $color-white;
		font-family: $font1;
		font-size: 28px;
		font-weight: 500;
		margin: 0;
		@include breakpoint(p_sm) {
			font-size: 32px;
		}
		@include breakpoint(p_md) {
			font-size: 36px;
		}
		@include breakpoint(p_lg) {
			font-size: 42px;
		}
		@include breakpoint(p_xl) {
			font-size: 48px;
		}
	}
	.main-color-btn {
		width: 243px;
		height: 60px;
		font-size: 17px;
		border-radius: 6px;
		margin: 30px auto 0 auto;
	}
}

.main-slider {
	padding: 50px 0;
	.swiper {
		position: relative;
		z-index: 2;
		@include breakpoint(p_sm) {
			padding: 0 60px;
		}
		@include breakpoint(p_xl) {
			padding: 0;
		}
	}
	.swiper-container {
		padding-bottom: 50px;
	}
	.swiper-slide {
		width: 280px;
		@include breakpoint(p_sm) {
			width: 306px;
		}
		@include breakpoint(p_md) {
			width: 380px;
		}
		@include breakpoint(p_lg) {
			width: 392px;
		}
		.img-wrap {
			position: relative;
			height: 165px;
			border: 4px solid $color-white;
			border-radius: 4px;
			box-shadow: $shadow;
			@include breakpoint(p_sm) {
				height: 208px;
			}
			@include breakpoint(p_lg) {
				height: 230px;
			}
		}
		.slide-descr {
			font-size: 15px;
			padding-top: 20px;
			@include breakpoint(p_sm) {
				font-size: 17px;
			}
			a {
				font-size: 20px;
				@include breakpoint(p_md) {
					font-size: 24px;
				}
			}
		}
	}
	.swiper-pagination-bullet {
		width: 10px;
		height: 10px;
	}
	.swiper-pagination-bullet-active {
		background-color: $color-main;
	}
	.swiper-button-prev,
	.swiper-button-next {
		display: none;
		width: 42px;
		height: 42px;
		@include breakpoint(p_sm) {
			display: block;
		}
		.svg-icon {
			width: 100%;
			height: 100%;
			fill: none;
			stroke: $color-main;
			&:hover {
				stroke: $color-main-hover;
			}
		}
		&::after {
			content: none;
		}
	}
	.swiper-button-prev {
		left: 0;
		@include breakpoint(1520px) {
			left: -140px;
		}
	}
	.swiper-button-next {
		right: 0;
		@include breakpoint(1520px) {
			right: -140px;
		}
	}
}

.typical-section {
	padding: 40px 0 50px 0;
	@include breakpoint(p_xl) {
		padding: 75px 0 90px 0;
	}
	&.v2 {
		padding: 30px 0 60px 0;
		@include breakpoint(p_xl) {
			padding: 30px 0 80px 0;
		}
	}
	&.v3 {
		padding: 30px 0 0 0;
	}
}

.tab {
	overflow: hidden;
	max-width: 280px;
	border-radius: 10px;
	margin: 10px auto;
	box-shadow: $shadow;
	@include breakpoint(p_sm) {
		max-width: none;
		margin: 10px 0;
	}
	.img-wrap {
		position: relative;
		display: block;
		height: 194px;
		@include breakpoint(p_sm) {
			height: 156px;
		}
		@include breakpoint(p_xl) {
			height: 200px;
		}
		&.v2 {
			height: 194px;
			@include breakpoint(p_sm) {
				height: 302px;
			}
			@include breakpoint(p_md) {
				height: 245px;
			}
			@include breakpoint(p_lg) {
				height: 212px;
			}
			@include breakpoint(p_xl) {
				height: 270px;
			}
		}
	}
	.tab-descr {
		height: 154px;
		text-align: center;
		background-color: $color-white;
		padding: 15px 24px 16px 24px;
		@include breakpoint(p_xl) {
			height: 180px;
			padding: 20px 24px 32px 24px;
		}
		&.v2 {
			height: auto;
			.tab-title-group {
				height: 134px;
			}
		}
		.tab-title-group {
			overflow: hidden;
			height: 100px;
			@include breakpoint(p_xl) {
				height: 100px;
			}
		}
		.tab-title {
			font-size: 20px;
			@include breakpoint(p_xl) {
				font-size: 24px;
			}
		}
		.tab-title-sub {
			color: $color-grey;
			font-size: 15px;
		}
		.price {
			color: $color-main;
			font-size: 20px;
			font-weight: 500;
		}
	}
}

img.soc {
	width: auto !important;
	padding: 0 !important;
}

.callback-form {
	background: $color-white;
	@include breakpoint(p_sm) {
		padding: 60px 0 0 0;
	}
	@include breakpoint(p_lg) {
		background: url(/static/img/bg1.jpg) center/cover no-repeat;
		padding: 100px 0;
	}
	.form-wrap {
		background-color: $color-black;
		padding: 30px 20px;
		margin: 0 -20px;
		@include breakpoint(p_sm) {
			border-radius: 10px;
			margin: 0;
		}
		@include breakpoint(p_md) {
			padding: 40px 20px;
		}
		@include breakpoint(p_lg) {
			padding: 40px 35px 50px 35px;
			border: 4px solid $color-white;
		}
		@include breakpoint(p_xl) {
			padding: 40px 60px 60px 60px;
		}
	}
	h2 {
		color: $color-white;
		font-family: $font1;
		font-size: 24px;
		font-weight: 500;
		margin-bottom: 20px;
		@include breakpoint(p_sm) {
			font-size: 28px;
			margin-bottom: 35px;
		}
		@include breakpoint(p_md) {
			font-size: 32px;
		}
		@include breakpoint(p_lg) {
			font-size: 36px;
			margin-bottom: 40px;
		}
	}
	.main-color-btn {
		width: 100%;
		height: 60px;
		font-size: 17px;
	}
}

.our-managers {
	.manageer-tab {
		margin: 10px 0;
	}
	.img-wrap {
		width: 120px;
		height: 120px;
		border: 4px solid $color-white;
		border-radius: 10px;
		box-shadow: $shadow;
		@include breakpoint(p_sm) {
			width: 186px;
			height: 186px;
		}
		@include breakpoint(p_md) {
			width: 100%;
			height: 229px;
		}
		@include breakpoint(p_lg) {
			width: 120px;
			height: 120px;
		}
		@include breakpoint(p_xl) {
			width: 186px;
			height: 186px;
		}
	}
	.manageer-descr {
		color: $color-grey;
		font-size: 15px;
		padding-left: 10px;
		@include breakpoint(p_sm) {
			padding-left: 20px;
		}
		@include breakpoint(p_md) {
			text-align: center;
			padding-left: 0;
			margin-top: 15px;
		}
		@include breakpoint(p_lg) {
			text-align: left;
			padding-left: 20px;
			margin-top: 0;
		}
		@include breakpoint(p_xl) {
			font-size: 17px;
		}
		.descr-title {
			color: $color-black;
			font-size: 20px;
			margin-bottom: 15px;
			@include breakpoint(p_xl) {
				font-size: 26px;
			}
		}
	}
	&.v1 {
		position: relative;
		z-index: 1;
		background-color: $color-white;
		margin-top: -40px;
		@include breakpoint(p_xl) {
			margin-top: -70px;
		}
		.adv-tabs {
			margin-top: 0;
		}
	}
}

.adv-tabs {
	margin-top: 40px;
	@include breakpoint(p_lg) {
		margin-top: 80px;
	}
}
.adv-tab {
	margin: 15px 0;
	@include breakpoint(p_lg) {
		margin: 25px 0;
	}
	.icon-wrap {
		width: 65px;
		height: 56px;
		margin-right: 15px;
		@include breakpoint(p_sm) {
			margin-right: 20px;
		}
		@include breakpoint(p_lg) {
			margin-right: 0;
		}
	}
	.tab-title {
		max-width: 290px;
		font-size: 20px;
		font-weight: 500;
		margin-top: 5px;
		@include breakpoint(p_lg) {
			max-width: 320px;
			margin-top: 15px;
		}
		@include breakpoint(p_xl) {
			font-size: 24px;
		}
	}
	.tab-p {
		color: $color-grey;
		font-size: 17px;
		margin-top: 15px;
	}
	.main-color-btn {
		height: 60px;
		font-size: 17px;
		border-radius: 6px;
		margin-top: 15px;
		@include breakpoint(p_sm) {
			width: 243px;
		}
	}
}

.thanks {
	overflow: hidden;
	position: relative;
	background: $color-black;
	padding-bottom: 0;
	h2 {
		color: $color-white;
		margin-bottom: 35px;
		@include breakpoint(p_xl) {
			margin-bottom: 100px;
		}
	}
	.bg-logo {
		position: absolute;
		bottom: 70px;
		left: 49.3%;
		z-index: 1;
		width: 560px;
		transform: translateX(-50%);
		@include breakpoint(p_sm) {
			width: 728px;
			left: 49.5%;
		}
		@include breakpoint(p_md) {
			left: 50%;
		}
		@include breakpoint(p_lg) {
			width: 960px;
		}
		@include breakpoint(p_xl) {
			width: auto;
		}
	}
	.swiper {
		position: relative;
		z-index: 2;
		padding: 0 52px;
		@include breakpoint(p_sm) {
			padding: 0 90px;
		}
		@include breakpoint(p_md) {
			padding: 0 100px;
		}
		@include breakpoint(p_lg) {
			padding: 0 74px;
		}
		@include breakpoint(p_xl) {
			padding: 0 80px;
		}
		.swiper-slide {
			display: block;
			font-size: 0;
			img {
				width: 100%;
				box-shadow: $shadow;
			}
		}
		.swiper-button-prev,
		.swiper-button-next {
			top: 36%;
			width: 42px;
			height: 42px;
			margin: 0;
			@include breakpoint(p_xl) {
				top: 27%;
			}
			.svg-icon {
				width: 100%;
				height: 100%;
				fill: none;
				stroke: $color-white;
				&:hover {
					stroke: $color-main;
				}
			}
			&::after {
				content: none;
			}
		}
		.swiper-button-prev {
			left: 0;
			@include breakpoint(1520px) {
				left: -140px;
			}
		}
		.swiper-button-next {
			right: 0;
			@include breakpoint(1520px) {
				right: -140px;
			}
		}
	}

	element.style {
		opacity: 1;
	}
	a[class*='lightcase-icon-'].lightcase-icon-close {
		position: fixed;
		top: 15px;
		right: 15px;
		bottom: auto;
		margin: 0;
		opacity: 0;
		outline: none;
	}
	a, a:hover, a:focus, a:visited {
		color: inherit;
		text-decoration: none;
		outline: none;
	}
}
a.lightcase-icon-close {
	color: rgba(255,255,255,0.6);
}


.map-wrap {
	position: relative;
	z-index: 1;
	height: 400px;
	margin-top: -70px;
	@include breakpoint(p_md) {
		height: 500px;
	}
}
#map3 {
	height: 400px;
	@include breakpoint(p_sm) {
		height: 500px;
	}
	@include breakpoint(p_md) {
		height: 600px;
	}
	@include breakpoint(p_lg) {
		height: 700px;
	}
	@include breakpoint(p_xl) {
		height: 800px;
	}
	.h-wrap {
		position: relative;
		top: 45px;
		z-index: 10;
		height: 0;
		text-align: center;
		background-color: transparent;
		@include breakpoint(p_lg) {
			text-align: left;
		}
		@include breakpoint(p_xl) {
			top: 90px;
		}
	}
}

.cat-tabs {
	h2 {
		margin-bottom: 20px;
	}
	.cats-menu {
		padding-top: 10px;
	}
}

.outloads {
	.video-wrap {
		overflow: hidden;
		height: 380px;
		margin: 50px -20px 0 -20px;
		@include breakpoint(p_sm) {
			border-radius: 10px;
			margin: 50px 0 0 0;
		}
		@include breakpoint(p_lg) {
			height: 500px;
		}
		@include breakpoint(p_xl) {
			height: 600px;
		}
		iframe {
			width: 100%;
			height: 100%;
		}
	}
}

.action-form-section {
	overflow: hidden;
	background-color: $color-white;
	border-radius: 10px;
	margin: 40px 0;
	box-shadow: $shadow;
	.img-wrap {
		height: 100%;
		font-size: 0;
	}
	h2 {
		font-size: 24px;
		@include breakpoint(p_sm) {
			font-size: 28px;
		}
		@include breakpoint(p_md) {
			font-size: 32px;
		}
		@include breakpoint(p_xl) {
			font-size: 36px;
		}
	}
	.form-block {
		padding: 30px 20px;
		@include breakpoint(p_md) {
			padding: 30px 30px 40px 30px;
		}
		@include breakpoint(p_lg) {
			padding: 30px 30px 50px 0;
		}
		@include breakpoint(p_xl) {
			padding: 30px 50px 50px 10px;
		}
	}
	.form-wrap {
		.form-zone {
			width: 100%;
			@include breakpoint(p_md) {
				width: 50%;
			}
			.main-color-btn {
				width: 100%;
				height: 60px;
				font-size: 17px;
			}
		}
		.text-zone {
			width: 100%;
			font-size: 15px;
			padding-left: 20px;
			@include breakpoint(p_sm) {
				font-size: 17px;
			}
			@include breakpoint(p_md) {
				width: 50%;
			}
			@include breakpoint(p_xl) {
				font-size: 20px;
				padding-left: 30px;
			}
			.links-group {
				font-size: 20px;
				@include breakpoint(p_sm) {
					font-size: 24px;
				}
			}
		}
	}
}

.article-content {
	background-color: $color-white;
	margin: 0 -20px 30px -20px;
	box-shadow: $shadow;
	@include breakpoint(p_sm) {
		border-radius: 10px;
		margin: 0 0 30px 0;
	}
	&:last-child {
		margin-bottom: 0;
	}
	.img-wrap {
		overflow: hidden;
		height: 170px;
		font-size: 0;
		border-radius: 4px;
		@include breakpoint(p_sm) {
			height: 243px;
		}
		@include breakpoint(p_md) {
			height: 203px;
		}
		@include breakpoint(p_lg) {
			height: 273px;
		}
		@include breakpoint(p_xl) {
			height: 300px;
		}
	}
	.article-body {
		padding: 30px 20px;
		@include breakpoint(p_xl) {
			padding: 30px 100px;
		}
		&.v2 {
			padding: 15px 20px;
			@include breakpoint(p_xl) {
				padding: 30px 20px;
			}
		}
		&.v3 {
			padding: 0;
		}
		.article-body-elem {
			padding: 20px;
			border-bottom: 1px solid $color-grey2;
			@include breakpoint(p_md) {
				padding: 30px 20px;
			}
			&:last-child {
				border: none;
			}
		}
	}
	.article-footer {
		padding: 30px 20px 40px 20px;
		border-top: 1px solid #eee;
		@include breakpoint(p_xl) {
			padding: 30px 100px 40px 100px;
		}
	}
	.article-tab {
		overflow: hidden;
		position: relative;
		height: 100%;
		background-color: $color-white;
		padding: 20px 25px;
		border: 1px solid $color-grey2;
		border-radius: 10px;
		.float-block {
			width: 70px;
			height: 60px;
			float: right;
		}
		.h4-wrap {
			margin-bottom: 15px;
			@include breakpoint(p_lg) {
				margin-bottom: 25px;
			}
			h4 {
				font-size: 20px;
				margin: 0;
				@include breakpoint(p_xl) {
					font-size: 24px;
				}
			}
			img {
				width: auto;
				position: absolute;
				top: 20px;
				right: 25px;
				z-index: 1;
				@include breakpoint(p_lg) {
					position: static;
				}
			}
		}
	}
	&.v2 {
		border-bottom: 1px solid $color-grey2;
		border-bottom-right-radius: 0;
		border-bottom-left-radius: 0;
	}
	&.v3 {
		border-top-right-radius: 0;
		border-top-left-radius: 0;
		margin-bottom: 10px;
	}
	&.typical-text {
		.article-body {
			img {
				width: 100%;
				padding: 15px 0 15px 0;
				@include breakpoint(p_md) {
					width: 50%;
					padding: 15px;
				}
			}
		}
	}
}

.reviews-wrapper {
	margin-top: 50px;
	.with-digit {
		display: inline-block;
		position: relative;
		margin-bottom: 0;
		span {
			display: block;
			position: absolute;
			right: -20px;
			bottom: 3px;
			z-index: 1;
			color: $color-grey;
			font-size: 13px;
			font-weight: normal;
			@include breakpoint(p_md) {
				font-size: 15px;
				bottom: 4px;
			}
			@include breakpoint(p_lg) {
				bottom: 6px;
			}
		}
	}
}
.reviews {
	font-size: 15px;
	.p-right {
		width: 100%;
		margin-top: 15px;
		@include breakpoint(p_md) {
			width: 170px;
			margin-top: 0;
		}
	}
	.digit {
		font-size: 32px;
		font-weight: 500;
		margin-right: 20px;
		@include breakpoint(p_sm) {
			margin-right: 40px;
		}
	}
	.revs-qty {
		display: none;
		margin-left: 34px;
		@include breakpoint(p_sm) {
			display: block;
		}
	}
	.main-color-btn {
		width: 100%;
		height: 50px;
		@include breakpoint(p_md) {
			max-width: 170px;
		}
	}
}
.tags {
	font-family: $font2;
	@include breakpoint(p_sm) {
		margin-top: 15px;
	}
	li {
		background-color: $color-white;
		padding: 10px 16px;
		border: 2px solid $color-main;
		border-radius: 3px;
		margin-top: 10px;
		margin-right: 10px;
		@include breakpoint(p_sm) {
			margin-top: 5px;
		}
	}
	&.with-anchors {
		li {
			padding: 0;
			a {
				display: block;
				padding: 10px 16px;
			}
		}
	}
}
.reviews-items {
	.reviews-item,
	.reviews-answer {
		.item-part-left {
			width: 100%;
			@include breakpoint(p_md) {
				width: 220px;
			}
			@include breakpoint(p_lg) {
				width: 275px;
			}
		}
		.user-name {
			@include breakpoint(p_md) {
				margin-top: 18px;
			}
			span {
				display: none;
				color: $color-white;
				font-size: 13px;
				background-color: #69a042;
				border-radius: 10px;
				padding: 3px 8px;
				margin-left: 10px;
				@include breakpoint(p_sm) {
					display: block;
				}
			}
		}
		.review-date {
			color: $color-grey;
			font-size: 13px;
			margin-top: 12px;
		}
		.item-content {
			line-height: 1.4;
			margin-top: 15px;
			@include breakpoint(p_md) {
				margin-top: 0;
			}
			h4 {
				color: $color-main;
				font-size: 17px;
				margin-bottom: 15px;
				@include breakpoint(p_md) {
					font-size: 20px;
				}
			}
			.item-link {
				color: $color-main;
				font-size: 13px;
				margin-top: 10px;
			}
			.thumbs {
				font-size: 15px;
				line-height: 1;
				.svg-icon {
					width: 17px;
					height: 17px;
					fill: transparent;
					margin-left: 7px;
				}
				.upthumb {
					color: #69a042;
					.svg-icon {
						stroke: #69a042;
					}
				}
				.downthumb {
					color: $color-main;
					margin-left: 20px;
					.svg-icon {
						stroke: $color-main;
					}
				}
			}
		}
	}
	.reviews-answer {
		padding: 20px;
		border: 1px solid $color-grey2;
		border-radius: 5px;
		margin-top: 20px;
		.item-part-left {
			width: 100%;
			@include breakpoint(p_md) {
				width: 200px;
			}
			@include breakpoint(p_lg) {
				width: 255px;
			}
		}
		.logo {
			width: 160px;
			@include breakpoint(p_lg) {
				width: 192px;
			}
		}
		.answer-name {
			color: $color-grey;
			font-size: 13px;
			margin-top: 12px;
		}
		.answer-date {
			color: $color-grey;
			font-size: 13px;
			margin-top: 20px;
		}
	}
}

.new-review {
	font-size: 15px;
	.left-part {
		width: 100%;
		@include breakpoint(p_lg) {
			width: 275px;
			padding-right: 30px;
		}
	}
	.right-part {
		.rating-group {
			margin-top: 20px;
			margin-right: 50px;
		}
		.big-span {
			font-size: 24px;
			font-weight: 500;
			margin-right: 25px;
			margin-bottom: 15px;
			@include breakpoint(p_sm) {
				margin-bottom: 0;
			}
		}
	}
	.main-color-btn {
		width: 100%;
		height: 50px;
		margin-bottom: 20px;
		@include breakpoint(p_md) {
			max-width: 170px;
			margin-right: 30px;
			margin-bottom: 0;
		}
	}
	.main-color-text {
		color: $color-main;
	}
}

.rating {
	display: flex;
	align-items: center;
}

.stars {
	.icon-star {
		width: 34px;
		height: 32px;
		fill: #e4e4e4;
		&.active {
			fill: $color-main;
		}
		+ .icon-star {
			margin-left: 12px;
		}
	}
	&.sm {
		.icon-star {
			width: 20px;
			height: 19px;
			+ .icon-star {
				margin-left: 7px;
			}
		}
	}
}

.item {
	.prod-img-wrap {
		.clmn {
			position: relative;
			padding: 0 10px;
		}
		.left-col {
			width: 80px;
			height: 293px;
			padding-top: 35px;
			padding-bottom: 35px;
			@include breakpoint(p_sm) {
				width: 20%;
			}
		}
		.right-col {
			width: calc(100% - 80px);
			@include breakpoint(p_sm) {
				width: 80%;
			}
		}
		.thumbs {
			height: 100%;
		}
		.swiper-slide {
			width: 100%;
			height: 60px;
			border-radius: 6px;
			cursor: pointer;
		}
		.swiper-btn {
			position: absolute;
			left: 50%;
			z-index: 10;
			margin-left: -11px;
			cursor: pointer;
			&.swiper-button-disabled {
				.svg-icon {
					stroke: $color-grey2;
				}
			}
			.svg-icon {
				width: 22px;
				height: 22px;
				stroke: $color-main;
				fill: none;
			}
		}
		.swiper-prev {
			top: 0;
		}
		.swiper-next {
			bottom: 0;
		}
		.prod-img {
			.swiper-slide {
				height: 293px;
			}
		}
	}
	.item-descr {
		font-size: 15px;
		@include breakpoint(p_xl) {
			padding-left: 30px;
		}
		table {
			td {
				min-width: 125px;
				font-size: 15px;
				padding: 2px 0;
				&:first-child {
					color: $color-grey;
				}
				&:last-child {
					font-size: 20px;
				}
			}
		}
		.icon-gift {
			display: inline-block;
			width: 22px;
			height: 22px;
			fill: none;
			stroke: $color-main;
			vertical-align: middle;
		}
		.item-form {
			margin: 25px 0 35px 0;
			.item-qty {
				width: 50%;
				padding: 0 15px;
				border-radius: 4px 0 0 4px;
				@include breakpoint(p_lg) {
					width: 100px;
				}
			}
			.item-units {
				width: 50%;
				border-radius: 0 4px 4px 0;
				margin-left: -1px;
				@include breakpoint(p_lg) {
					width: 100px;
				}
			}
			.main-color-btn {
				width: 100%;
				height: 50px;
				@include breakpoint(p_lg) {
					width: 150px;
				}
			}
		}
	}
}

.nav-tabs {
	border: none;
	.nav-link {
		color: $color-main;
		font-size: 24px;
		font-weight: 500;
		background-color: transparent;
		padding: 0 0 13px 0;
		border: none;
		border-bottom: 2px solid transparent;
		border-radius: 0;
		@include breakpoint(p_lg) {
			font-size: 32px;
		}
		@include breakpoint(p_xl) {
			padding: 0 0 28px 0;
		}
		+ .nav-link {
			margin-left: 25px;
			@include breakpoint(p_sm) {
				margin-left: 50px;
			}
		}
		&.active {
			color: $color-black;
			background-color: transparent;
			border-bottom-color: $color-main;
		}
		&.with-digit {
			position: relative;
			span {
				display: block;
				position: absolute;
				right: -20px;
				bottom: 18px;
				z-index: 1;
				color: $color-grey;
				font-size: 15px;
				font-weight: normal;
				@include breakpoint(p_xl) {
					bottom: 34px;
				}
			}
		}
	}
}

.delivery {
	.form-wrap {
		background-color: $color-black;
		padding: 35px 20px;
		margin: 0 -20px 20px -20px;
		@include breakpoint(p_sm) {
			padding: 35px;
			border: 4px solid $color-white;
			border-radius: 10px;
			margin: 0 0 20px 0;
		}
		@include breakpoint(p_xl) {
			padding: 35px 70px;
		}
		.label {
			color: $color-white;
			font-size: 17px;
			margin-bottom: 10px;
		}
		.jq-selectbox {
			width: 100%;
			height: 60px;
			background-color: $color-white;
			border: none;
		}
		.field-group {
			.field,
			.jq-selectbox {
				width: 50%;
			}
			.field {
				border-right: 1px solid $color-black;
				border-radius: 4px 0 0 4px;
			}
			.jq-selectbox {
				border-radius: 0 4px 4px 0
			}
		}
		.main-color-btn {
			width: 100%;
			height: 60px;
			font-size: 17px;
		}
	}
	.py-10 {
		padding-top: 10px;
		padding-bottom: 10px;
	}
}

.contacts {
	.left-part {
		width: 100%;
		@include breakpoint(p_md) {
			width: 67%;
		}
		@include breakpoint(p_lg) {
			width: 50%;
		}
	}
	.right-part {
		width: 0;
		@include breakpoint(p_md) {
			width: 33%;
		}
		@include breakpoint(p_lg) {
			width: 50%;
		}
	}
	.article-content {
		overflow: hidden;
		.article-header {
			padding: 40px 25px;
			border-bottom: 1px solid $color-grey2;
			@include breakpoint(p_md) {
				padding: 40px 25px 40px 35px;
			}
			@include breakpoint(p_xl) {
				padding: 40px 70px 40px 80px;
			}
		}
		.article-body {
			padding: 40px 25px;
			@include breakpoint(p_md) {
				padding: 40px 25px 40px 35px;
			}
			@include breakpoint(p_xl) {
				padding: 40px 70px 40px 80px;
			}
		}
		.tels-group {
			.group-elem:first-child {
				margin-right: 35px;
				@include breakpoint(p_xl) {
					margin-right: 65px;
				}
			}
			.tel {
				font-size: 17px;
				font-weight: 500;
				@include breakpoint(p_md) {
					font-size: 20px;
				}
			}
		}
		.mail {
			color: $color-main;
			font-size: 17px;
			font-weight: 500;
			@include breakpoint(p_md) {
				font-size: 20px;
			}
		}
		.socials-wrap {
			margin: 35px 0;
			.socials-group {
				margin-right: 20px;
			}
			span {
				font-size: 17px;
				font-weight: 500;
				@include breakpoint(p_xl) {
					font-size: 20px;
				}
			}
		}
		.requisites {
			color: $color-grey2;
			font-size: 13px;
			p {
				margin: 0;
			}
		}
	}
	.map-wrap {
		height: 350px;
		margin: 25px -25px;
		@include breakpoint(p_sm) {
			margin: 0 -25px 25px -25px;
		}
		@include breakpoint(p_md) {
			height: 100%;
			margin: 0;
		}
	}
}

.pre-footer {
	padding: 50px 0 10px 0;
	.cat-descr {
		font-size: 18px;
		padding-bottom: 15px;
	}
}

.manager-block {
	padding-bottom: 60px;
	.left-part {
		width: 100%;
		@include breakpoint(p_md) {
			width: 30%;
			padding: 0 15px;
		}
		@include breakpoint(p_lg) {
			width: 34.5%;
		}
		img {
			width: 100%;
			max-width: 220px;
			margin-bottom: 20px;
			@include breakpoint(p_md) {
				max-width: none;
				margin-bottom: 40px;
			}
		}
	}
	.right-part {
		width: 100%;
		background-color: $color-white;
		padding: 15px;
		margin-top: 30px;
		box-shadow: $shadow;
		@include breakpoint(p_md) {
			width: 70%;
			margin-top: 0;
		}
		@include breakpoint(p_lg) {
			width: 65.5%;
		}
	}
	.main-color-btn {
		width: 160px;
		height: 40px;
		font-size: 13px;
		@include breakpoint(p_lg) {
			width: 200px;
			font-size: 15px;
		}
	}
	.conts {
		margin: 20px 0;
	}
	.specials {
		width: 100%;
		margin: 0 auto;
		@include breakpoint(p_lg) {
			width: 288px;
		}
		.headline {
			color: $color-white;
			font-size: 16px;
			background-color: $color-main;
			padding: 5px 0;
			border-radius: 3px;
			margin-bottom: 10px;
			@include breakpoint(p_lg) {
				font-size: 18px;
			}
		}
		ul {
			font-size: 15px;
			text-align: left;
			@include breakpoint(p_lg) {
				font-size: 17px;
			}
			li {
				a {
					display: block;
					padding: 10px 15px;
					background-color: $color-white;
					border-radius: 3px;
					box-shadow: $shadow;
					@include breakpoint(p_lg) {
						padding: 15px 18px;
					}
				}
				+ li {
					margin-top: 3px;
				}
			}
		}
	}
}
/* </main */


/* modals */
.modal-dialog {
	max-width: 480px;
}
.modal-content {
	background-color: $color-black;
	border: 4px solid $color-white;
	border-radius: 10px;
}
.modal-header {
	position: relative;
	color: $color-white;
	padding: 35px 20px 15px 20px;
	border: none;
	@include breakpoint(p_sm) {
		padding: 35px 65px 15px 65px;
	}
}
.modal-title {
	line-height: 1;
}
.crossy {
	position: absolute;
	top: 25px;
	right: 25px;
	z-index: 1;
	width: 22px;
	height: 22px;
	background-color: transparent;
	.svg-icon {
		width: 22px;
		height: 22px;
		stroke: #5C5C5C;
		fill: none;
	}
	&:hover {
		.svg-icon {
			stroke: $color-white;
		}
	}
}
.modal-body {
	padding: 15px 20px 35px 20px;
	@include breakpoint(p_sm) {
		padding: 15px 65px 35px 65px;
	}
	.main-color-btn {
		width: 100%;
		height: 60px;
		font-size: 17px;
	}
}
/* </modals */


/* footer */
footer {
	.item-logo {
		margin-bottom: 30px;
		@include breakpoint(p_sm) {
			margin-bottom: 40px;
		}
	}
	.logo {
		width: 278px;
		@include breakpoint(p_sm) {
			width: 208px;
		}
		@include breakpoint(p_md) {
			width: 215px;
		}
		@include breakpoint(p_xl) {
			width: 226px;
		}
	}
	.subtext {
		font-size: 18.5px;
		@include breakpoint(p_sm) {
			font-size: 13.75px;
		}
		@include breakpoint(p_md) {
			font-size: 14.25px;
		}
		@include breakpoint(p_xl) {
			font-size: 15px;
		}
	}
	.item-subp {
		font-size: 17px;
		&.v1 {
			font-size: 17px;
			@include breakpoint(p_xl) {
				font-size: 20px;
			}
		}
	}
	.links-group {
		font-size: 24px;
	}
	.top-footer {
		padding: 40px 0 25px 0;
		@include breakpoint(p_md) {
			padding: 60px 0;
		}
		.main-color-btn {
			width: 200px;
			height: 50px;
			font-size: 15px;
			@include breakpoint(p_sm) {
				margin-left: 35px;
			}
			@include breakpoint(p_md) {
				margin-left: 45px;
			}
			@include breakpoint(p_lg) {
				margin: 0;
			}
			&.v2 {
				margin-left: 0;
			}
		}
		&.v2 {
			padding-top: 0;
		}
	}
	.socials-group {
		margin: 25px 0 0 0;
		@include breakpoint(p_sm) {
			margin: 0;
		}
		@include breakpoint(p_md) {
			margin: 30px 0 0 0;
		}
	}
	.btm-footer {
		background-color: $color-black;
		.btm-footer-inner {
			height: 80px;
		}
	}
}
/* </footer */







